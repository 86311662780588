mark {
  background: url(/assets/img/yellow-highlight.svg) !important;
  background-repeat: round !important;
  background-size: cover !important;
  margin: -2px -6px;
  padding:  2px  6px;
}

a.traditional {
  text-decoration: underline;
}

.form-control {
  font-size: 20px;
}

.icon svg {
  width: 32px;
  height: 32px;
}

.u-grayscale {
  filter: grayscale(1);
}

.logo-in-wall {
  max-height: 80px;
  object-fit: contain;
}
